/* Container for the filter bar */
.filter-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

/* Main filter bar styling */
.filter-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--second-background-color);
    border: 2px solid var(--second-background-color);
    border-radius: 8px;
    width: 100%;
    transition: all 0.3s ease-in-out;
}

/* Container for filter items */
.filter-items {
    width: 100%;
    position: relative;
}

/* Individual filter item styling */
.filter-item {
    display: flex;
    align-items: center;
    padding: 10px;
    color: var(--first-background-color);
    cursor: pointer;
    justify-content: space-between;
}

.filter-item:nth-child(1) {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.filter-item:nth-child(3) {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.filter-item:nth-child(1), 
.filter-item:nth-child(2) {
    border-right: 1px solid var(--second-background-color);
}

.filter-item:hover {
    background-color: var(--first-background-color);
}

.filter-item:hover > .filter-content > .filter-icon, 
.filter-item:hover .filter-text, 
.filter-item:hover .filter-label {
    color: var(--text-color);
}

/* Filter content styling */
.filter-content {
    display: flex;
    align-items: center;
    width: 100%;
}

/* Icon within filter item */
.filter-content > .filter-icon {
    margin-right: 12px;
    margin-left: 6px;
    font-size: 12pt;
    color: var(--first-background-color);
}

/* Filter label styling */
.filter-label {
    font-size: 10pt;
    color: var(--first-background-color);
}

/* Container for filter text */
.filter-text-container {
    display: flex;
    flex-direction: column;
    line-height: 1.3;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Filter text styling */
.filter-text {
    font-size: 11pt;
    white-space: nowrap;
    color: var(--first-background-color);
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.filter-text .filter-icon {
    background-color: var(--second-background-color);
    color: var(--first-background-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: fit-content;
    padding: 1px;
    flex-shrink: 0; /* Prevent icon from shrinking */
}

.filter-text .filter-icon:hover {
    background-color: var(--second-background-color);
    color: var(--first-background-color);
}

/* Styling for location filter text */
.location-filter-text div {
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Button for filter search */
.filter-search-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: fit-content;
    background-color: var(--first-background-color);
    color: var(--text-color);
    border: 2px solid var(--second-background-color);
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter-search-button:hover {
    background-color: var(--first-background-color);
}

/* Mobile filter bar styling */
.mobile-filter-bar {
    display: none;
    justify-content: space-between;
    align-items: center;
    border: 2px solid var(--first-background-color);
    color: var(--first-background-color);
    cursor: pointer;
}

.mobile-filter-bar .preferences {
    font-size: small;
    color: var(--first-background-color);
    font-style: italic;
}

/* Text truncation */
.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Styling for state name */
.state-name {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Filter modal styling */
.filter-modal .modal-dialog {
    margin: 10px;
}

.filter-modal .modal-content {
    height: calc(100vh - 20px);
    background-color: var(--first-background-color);
}

.filter-modal .modal-header .btn-close {
    background-color: var(--first-background-color);
    border-radius: 50%;
    font-size: smaller;
}

.filter-modal .modal-footer {
    justify-content: start;
}

.filter-modal .accordion {
    border: none;
}

.filter-modal .accordion-body {
    padding: 0;
}

.filter-modal .accordion-item {
    background-color: var(--second-background-color);
    border: none;
}

.filter-modal .accordion-header {
    background-color: transparent;
}

.filter-modal .accordion-button:focus {
    outline: none;
    box-shadow: none; 
}

.filter-modal .accordion-button.collapsed {
    background-color: transparent;
    color: var(--first-background-color);
}

.filter-modal .accordion-button:not(.collapsed) {
    background-color: transparent;
    color: var(--first-background-color);
}

/* Hide the default arrow */
.filter-modal .accordion-button::after {
    display: none;
}

.filter-modal .clear-filters-btn {
    color: var(--first-background-color);
    background-color: var(--second-background-color);
    border: 1px solid var(--first-background-color);
}

.filter-modal .clear-filters-btn:active {
    color: var(--first-background-color);
    background-color: var(--second-background-color);
    border: 1px solid var(--first-background-color);
}

.filter-modal .search-filters-btn {
    border: 1px solid var(--second-background-color);
    background-color: var(--first-background-color);
    color: var(--text-color);
}

.filter-modal .search-filters-btn:active {
    border: 1px solid var(--second-background-color);
    background-color: var(--first-background-color);
    color: var(--text-color);
}

/* Responsive adjustments */
@media (max-width: 992px) {
    .filter-bar {
        display: none;
    }

    .mobile-filter-bar {
        display: flex;
        width: 100%;
    }

    .location-filter-text > * {
        padding-left: 0;
    }
}