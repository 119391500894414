.weather-banner {
    padding: 20px;
    background-color: var(--second-background-color);
    color: var(--first-background-color);
    border-radius: 8px;
    border: 2px solid var(--second-background-color);
}
 
 .weather-main {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 15px;
}
 
.weather-icon {
    width: 80px;
    height: 80px;
}
 
.weather-info {
    text-transform: capitalize;
    font-size: 1.2rem;
    font-weight: 500;
}
 
.weather-temp {
    font-size: 2rem;
    font-weight: bold;
}
 
.weather-details {
    display: flex;
    align-items: start;
    flex-direction: column;
}
 
.weather-details p {
    margin: 0;
    font-size: 1rem;
}

@media (max-width: 992px) {
    .weather-banner {
        padding: 15px; 
    }

    .weather-main {
        justify-content: center;
    }

    .weather-temp {
        font-size: 1.5rem;
    }

    .weather-info {
        font-size: 1rem;
    }
}