.mobile-images .image-and-button-container {
    position: relative;
}

.see-all-photos-button-mobile {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px 0;
    font-weight: bold;
    color: var(--first-background-color);
    background: linear-gradient(transparent, rgba(0, 0, 0, 1));
    text-align: center;
    width: 100%;
    box-sizing: border-box;
}