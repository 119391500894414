/* Apartment details container */
.apartment-details {
    padding: 20px;
    padding-bottom: 0;
}

/* City name styling */
.apartment-details .city {
    text-transform: uppercase;
    font-size: 1rem; /* Responsive font size */
    font-weight: bold;
    color: var(--icon-color);
    letter-spacing: 2px;
}

/* Apartment name styling */
.apartment-details .name {
    font-family: 'Playfair Display', serif;
    font-size: 3.5rem; /* Responsive font size */
    font-weight: bold;
}

/* Apartment information container */
.apartment-details .info {
    display: flex; 
    margin-top: 10px;
}

/* Individual info item */
.apartment-details .info div {
    display: flex;
    align-items: center;
}

/* Icon styling in info section */
.apartment-details .icon {
    margin-right: 10px;
    color: var(--icon-color);
    font-size: 1.2rem;
}

/* Text next to icon */
.apartment-details .icon + span {
    font-weight: bold;
    font-size: 1.2rem; /* Responsive font size */
}

/* Description styling */
.apartment-details .description {
    margin-top: 20px;
    font-size: 1.2rem;
    white-space: pre-line;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    transition: max-height 0.3s ease;
}

/* Collapsed description */
.apartment-details .description.collapsed {
    line-clamp: 7;
    -webkit-line-clamp: 7; /* Limits to 7 lines */
}

/* Expanded description */
.apartment-details .description.expanded {
    line-clamp: unset;
    -webkit-line-clamp: unset;
    max-height: none;
}

/* Toggle button for description */
.apartment-details .toggle-button {
    margin-top: 10px;
    background: none;
    border: none;
    color: var(--second-background-color);
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
    padding: 0;
}

.apartment-details .reviews-container .toggle-button {
    color: var(--first-background-color);
}

/* CIN line style */
.apartment-details .cin-container {
    margin-top: 20px;
    color: var(--text-color);
    font-size: 0.8rem;
}

/* Reviews container styling */
.reviews-container {
    margin-top: 60px;
}

/* Reviews section */
.reviews-container .reviews {
    padding: 30px;
    background-color: var(--second-background-color);
    border-radius: 8px;
    color: var(--first-background-color);
}

/* Author name styling */
.reviews-container .author {
    color: var(--first-background-color);
    margin-bottom: 10px;
}

/* Review text styling */
.reviews-container .review {
    font-size: 1rem;
    white-space: pre-line;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    transition: max-height 0.3s ease;
}

/* Collapsed review */
.reviews-container .review.collapsed {
    line-clamp: 3;
    -webkit-line-clamp: 3; /* Limits to 3 lines */
}

/* Expanded review */
.reviews-container .review.expanded {
    line-clamp: unset;
    -webkit-line-clamp: unset;
}

/* No reviews message */
.no-reviews {
    font-size: 1rem;
    color: var(--first-background-color);
    padding: 20px;
    margin-top: 20px;
    background-color: var(--second-background-color);
    border-radius: 8px;
    text-align: center; /* Center the text */
}

/* Recommendations title */
.recommendations .title {
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--icon-color);
    letter-spacing: 2px;
}

/* No recommendations message */
.no-recommendations {
    font-size: 1rem;
    color: var(--first-background-color); 
    padding: 20px;
    margin-top: 20px;
    background-color: var(--second-background-color);
    border-radius: 8px;
}

/* Amenities container styling */
.amenities-container {
    background-color: var(--first-background-color);
    padding: 30px;
    margin-top: 30px;
    color: var(--text-color);
    border-radius: 8px;
}

/* Row styling in amenities container */
.amenities-container .row {
    row-gap: 30px;
}

/* Individual amenity item styling */
.amenity-item {
    display: flex;
    align-items: baseline;
}

/* Icon styling in amenities section */
.amenity-icon {
    color: var(--icon-color);
    font-size: 24px;
    margin-right: 15px;
}

/* Amenity name styling */
.amenity-name {
    font-size: 16px;
    font-weight: bold;
}

/* Media Queries for Smaller Screens */
@media (max-width: 576px) {
    /* Adjust apartment details padding */
    .apartment-details {
        padding-bottom: 30px;
    }

    /* Smaller font for city name */
    .apartment-details .city {
        font-size: 0.9rem;
    }

    /* Smaller font for apartment name */
    .apartment-details .name {
        font-size: 2rem;
    }

    /* Smaller font for icons */
    .apartment-details .icon {
        font-size: 1rem;
    }

    /* Smaller font for text next to icons */
    .apartment-details .icon + span {
        font-size: 1rem;
    }

    /* Smaller font for description */
    .apartment-details .description {
        font-size: 1rem;
    }

    /* Smaller font for recommendations title */
    .recommendations .title {
        font-size: 1rem;
    }

    .amenities-container {
        margin-top: 10px;
    }
}