/* Container for the map */
.map-container {
    position: relative;
}

/* Box showing coordinates */
.coordinates-box {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: var(--second-background-color);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: var(--text-color);
    z-index: 10;
    font-family: Arial, sans-serif;
}

.coordinates-box p {
    margin: 0;
    font-size: 14px;
}

/* Popup styling */
.mapboxgl-popup {
    width: 400px !important;
}

.mapboxgl-popup-content {
    background-color: var(--first-background-color);
    color: var(--text-color);
    padding: 0;
    border-radius: 10px !important;
    box-shadow: 0 0 5px 1px grey;
    cursor: pointer;
}

.mapboxgl-popup-close-button {
    background-color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--second-border-color);
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 20px;
    padding: 0;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
    color: transparent; /* Hide the default content */
    z-index: 2;
}

.mapboxgl-popup-close-button::before {
    content: '\00d7'; /* Unicode for "X" */
    font-size: 20px;
    color: black; 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transform: translate(38%, -5%);
}

.mapboxgl-popup-close-button:hover {
    background-color: #e0e0e0;
}

.mapboxgl-popup-close-button:focus {
    outline: none;
}

/* Carousel container within popup */
.popup-carousel-container {
    position: relative;
    width: 100%;
    height: 200px; /* Fixed height matching image */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0; /* Background color while loading */
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    overflow: hidden; /* Ensure no overflow */
}

.popup-carousel-container .carousel-inner {
    height: 100%;
}

.popup-carousel-container .slide {
    height: 100%;
    width: 100%;
}

.popup-carousel-container .carousel-item {
    height: 100%;
}

.popup-carousel-container .carousel-indicators {
    margin-bottom: 0;
}

.popup-carousel-image {
    width: 100%;
    height: 200px;
    object-fit: cover; /* Fit image within container without distortion */
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}



.popup-carousel-container .carousel-indicators [data-bs-target] {
    width: 7px;
    height: 7px;
    border-radius: 50%;
}

.popup-carousel-container .carousel-control-prev, 
.popup-carousel-container .carousel-control-next {
    font-size: 20px;
    color: white;
}

/* Loading message styling */
.loading-message {
    position: absolute;
    font-size: 16px;
    color: var(--icon-color);
    background-color: var(--second-background-color);
    text-align: center;
    width: 100vw; /* Full width of container */
    height: 100%; /* Full height of container */
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box; /* Ensure padding does not affect size */
}

/* Popup content styling */
.popup-content {
    padding: 10px;
    font-size: 14px;
}

.popup-content p {
    margin-bottom: 5px;
}

/* Apartment info within popup */
.popup-apartment-info {
    display: flex;
    gap: 15px;
}

.popup-apartment-icon {
    margin-right: 5px;
    color: var(--icon-color);
}

.popup-apartment-icon + span {
    color: var(--text-color);
}

/* Marker icon styling */
.marker-icon {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.marker-icon:hover {
    transform: scale(1.2);
}

.marker-icon img {
    width: 100%;
    height: auto;
}

/* Cluster marker styling */
.cluster-marker {
    background-color: var(--first-background-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
}

/* Hide popup tip */
.mapboxgl-popup-tip {
    display: none;
}

/* Hover message styling */
.hover-message {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    color: var(--text-color);
    padding: 10px 20px;
    border-radius: 5px;
    z-index: 995;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hover-message div {
    transform: translateY(30px);
    text-transform: uppercase;
    padding: 10px 20px;
    background-color: var(--first-background-color);
    opacity: 0.7;
    border-radius: 10px;
}

/* Responsive adjustments */
@media (max-width: 576px) {
    .coordinates-box {
        font-size: 12px;
        padding: 8px;
    }

    .mapboxgl-popup {
        max-width: calc(100vw - 20px) !important;
        position: absolute !important;
        left: 10px !important;
        top: 10px !important;
        transform: none !important; /* Disable any transformation */
    }

    .popup-carousel-container {
        width: calc(100vw - 20px);
    }

    .loading-message {
        left: 0;
        top: 0;
    }
}