/* Modal content styling for the contact us modal */
.contactus-modal .modal-content {
    background-color: var(--second-background-color);
    border: 2px solid var(--first-background-color);
    color: var(--first-background-color);
}

/* Close button styling for the contact us modal */
.contactus-modal .btn-close {
    background-color: var(--second-background-color);
    outline: none; 
    box-shadow: none;
}

/* Contact information container styling */
.contact-info {
    margin-top: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
  
/* Contact item styling */
.contact-item {
    display: flex;
    align-items: center;
}
  
/* Contact icon styling */
.contact-icon {
    margin-right: 10px;
    color: var(--first-background-color);
}
  
/* Contact item link styling */
.contact-item a {
    color: var(--first-background-color);
    text-decoration: none;
}

/* Remove bottom margin for contact item paragraphs */
.contact-item p {
    margin-bottom: 0;
}

/* Social icons container styling */
.contact-social-icons {
    font-size: 18pt;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
}

/* Social icons link styling */
.contact-social-icons a {
    color: var(--first-background-color);
}

/* Additional styling for larger screens */
@media (min-width: 768px) {
    /* Hover effect for social icons on larger screens */
    .contact-social-icons a:hover {
        color: var(--first-background-color);
    }

    /* Hover effect for contact item links on larger screens */
    .contact-item a:hover {
        text-decoration: underline;
        color: var(--text-color);
    }
}