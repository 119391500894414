/* Modal content styling for the contact us modal */
.aboutUs-modal .modal-content {
    background-color: var(--second-background-color);
    border: 2px solid var(--first-background-color);
    color: var(--first-background-color);
}

.aboutUs-modal .modal-header {
    padding: 0 10px 0 0;
}

.aboutUs-modal .modal-title {
    padding-left: 10px;
}

.aboutUs-modal .logo-container {
    background-color: var(--first-background-color);
    padding: 10px;
}

.aboutUs-modal .modal-body {
    padding: 30px;
}

/* Close button styling for the about us modal */
.aboutUs-modal .btn-close {
    background-color: var(--second-background-color);
    outline: none; 
    box-shadow: none;
}
