.options-bar-container {
    position: relative;
    width: 100%;
}

.fixed-carousel {
    position: fixed;
    top: 100px; /* Adjust as needed */
    width: 100%; /* Ensure it takes the full width */
    z-index: 998; /* Ensure it's above other content */
    background: var(--first-background-color);
}
  
.tab-item {
    color: var(--text-color);
    cursor: pointer;
    padding: 10px;
    text-align: center;
    min-width: 80px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
  
.tab-item:hover {
    background-color: var(--second-background-color);
    color: var(--first-background-color);
    border-radius: 8px;
}
  
.tab-item.active {
    color: var(--second-background-color);
    border-bottom: 2px solid var(--second-background-color);
}

.tab-item.active:hover {
    color: var(--first-background-color);
}

.react-multiple-carousel__arrow {
    background: var(--second-background-color);  
    opacity: 0.7; 
    border-radius: 0;
    transition: none;
    min-height: 44px;
    min-width: 30px;
    transform: translateY(-1px);
}

.react-multiple-carousel__arrow::before {
    color: var(--first-background-color);
}

.react-multiple-carousel__arrow:hover {
    background: var(--first-background-color);
}

.react-multiple-carousel__arrow:hover::before {
    color: var(--text-color);
}

.react-multiple-carousel__arrow--right {
    right: 0px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.react-multiple-carousel__arrow--left {
    left: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

/* Disable hover effects on mobile devices */
@media (max-width: 768px) {
    .tab-item:hover {
        background-color: transparent; /* Reset hover background */
        color: var(--text-color); /* Reset hover color */
    }

    .tab-item.active:hover {
        color: var(--second-background-color); /* Reset active hover color */
    }

    .react-multiple-carousel__arrow:hover {
        background: var(--second-background-color); /* Reset arrow hover background */
    }

    .react-multiple-carousel__arrow:hover::before {
        color: var(--first-background-color); /* Reset arrow hover text color */
    }
}