/* Footer container styling */
.footer {
  margin: 80px 0 80px;
  padding: 0 50px;
}

/* Footer logo container */
.footer-logo {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  font-size: 11pt;
}

/* Footer logo image styling */
.footer-logo img {
  height: 30px;
  margin-bottom: 10px;
}

/* Footer icons container styling */
.footer-icons {
  font-size: 18pt;
  display: flex;
  gap: 20px;
}

/* Footer icons link styling */
.footer-icons a {
  color: var(--text-color);
}

/* Footer section headings styling */
.footer-section h5 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}

/* Footer section list styling */
.footer-section ul {
  list-style: none;
  padding: 0;
}

/* Footer section list items styling */
.footer-section ul li {
  margin-bottom: 10px;
}

/* Footer link styling inside list items */
.footer-section ul li .footer-link {
  color: var(--icon-color);
  text-decoration: none;
  cursor: pointer;
}

/* Hover effect for footer links */
.footer-section ul li .footer-link:hover {
  text-decoration: underline;
}
