.maintenance-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #da5b5b;
    animation: fadeIn 2s ease-in-out;
}
  
.maintenance-content {
    max-width: 600px;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: slideInFromLeft 1s ease, pulse 2s infinite;
}
  
.maintenance-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
    animation: textSlideIn 1.5s ease-out;
}
  
.maintenance-container p {
    font-size: 1.2em;
    color: #666;
    animation: textSlideIn 1.5s ease-out 0.5s;
}
  
/* Fade-in animation for the container */
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
  
/* Slide-in animation for content */
@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
}
  
/* Pulsing animation for content */
@keyframes pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
}
  
/* Text slide-in animation */
@keyframes textSlideIn {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
}
  
/* Mobile adjustments for tablets and large smartphones */
@media (max-width: 768px) {
    .maintenance-content {
      padding: 15px;
      max-width: 90%;
    }
  
    .maintenance-container h1 {
      font-size: 2em;
    }
  
    .maintenance-container p {
      font-size: 1em;
    }
}
  
/* Mobile adjustments for small smartphones */
@media (max-width: 576px) {
    .maintenance-content {
      padding: 10px;
      max-width: 95%;
    }
  
    .maintenance-container h1 {
      font-size: 1.5em;
    }
  
    .maintenance-container p {
      font-size: 0.9em;
    }
}