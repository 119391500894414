.referafriend-modal .modal-content {
    background-color: var(--second-background-color);
    border-radius: 10px; 
    border: 2px solid var(--first-background-color);
    color: var(--first-background-color);
}
  
.referafriend-modal .modal-body {
    padding: 20px;
}

.referafriend-modal .btn-close {
    outline: none; 
    box-shadow: none; 
}

.popover-body {
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 12px;
    color: var(--second-background-color);
}

.referafriend-modal .copy-btn {
    border: 1px solid var(--first-background-color);
    background-color: var(--second-background-color);
    color: var(--first-background-color);
}

.referafriend-modal .copy-btn:hover {
    background-color: var(--first-background-color);
    border: 1px solid var(--second-background-color);
    color: var(--second-background-color);
}