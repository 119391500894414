.loading-container {
    position: fixed; /* Fix it in the center of the screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.apartment-page {
    position: relative;
}

.apartment-page.hidden {
    display: none;
}

.apartment-page .placeholder-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: var(--second-background-color);
    color: var(--text-color); 
    font-size: 16px; 
    font-weight: bold; 
    text-align: center;
}

/* Pulsante per tornare indietro */
.back-button {
    width: 30px;
    height: 30px;
    position: fixed;
    top: 30px; 
    left: 20px; 
    background: transparent;
    border: 1px solid var(--first-border-color); 
    border-radius: 50%; 
    padding: 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    cursor: pointer; 
    z-index: 1000; 
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.back-button svg {
    font-size: 20px;
    color: white; 
}

.calendar-container {
    background-color: var(--first-background-color);
    height: fit-content;
    padding: 20px 10px;
    border: 2px solid var(--second-background-color);
    border-radius: 8px;
    margin-left: 20px;
    margin-right: 20px;
}

.calendar-fixed {
    position: sticky;
    top: var(--calendar-top-offset);
    right: 12px;
    z-index: 998;
}

.calendar-label {
    font-weight: bold;
    font-size: 13pt;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--second-border-color);
}

.calendar-container .button-container {
    padding-top: 15px;
    margin-top: 15px;
    border-top: 1px solid #cccccc;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.recent-views {
    display: flex;
}

.clear-btn, .reserve-btn, .recent-views button {
    flex-grow: 1;
}

.clear-btn {
    border: 1px solid var(--second-background-color);
    background-color: var(--first-background-color);
    color: var(--text-color);
}

.clear-btn:active {
    background-color: var(--second-background-color) !important;
    border-color: var(--second-background-color) !important;
}

.reserve-btn {
    border: 1px solid var(--second-background-color);
    background-color: var(--second-background-color);
    color: var(--first-background-color);
}

.reserve-btn:disabled {
    background-color: var(--second-background-color) !important;
    border: 1px solid var(--second-background-color) !important;
}

.reserve-btn:active, .recent-views button:active {
    background-color: var(--second-background-color) !important;
    border-color: var(--second-background-color) !important;
}

.recent-views button {
    margin: 5px 20px 20px;
    color: var(--text-color);
    background-color: var(--first-background-color);
    border: none;
    white-space: normal;
}

/* Vertical calendar */

.vertical-scroll-calendar {
    max-height: calc(100vh - 100px - 10px - 20px - 57px - 70px - 20px - 20px - 46px); 
    overflow-y: scroll;
}

.vertical-scroll-calendar .rdp {
    display: flex;
    margin: 0;
    padding: 0;
    background-color: var(--first-background-color);
}

.vertical-scroll-calendar .rdp-month {
    margin: 0;
}

.vertical-scroll-calendar .rdp-months {
    flex-direction: column; 
}

.vertical-scroll-calendar .rdp-day_range_middle {
    background-color: var(--second-background-color) !important;
    color: var(--first-background-color);
}

.vertical-scroll-calendar .rdp-day_selected {
    background-color: var(--second-background-color) !important;
}

.map-details-container {
    margin: 60px 20px 0;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}

.weather-container {
    margin: 30px 20px 0;
}

.recommendations-container {
    margin: 60px 20px 0;
}

.apartment-page + .iframe-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 90%; 
    background-color: var(--first-background-color);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    border-radius: 10px;
}
  
.apartment-page + .iframe-container iframe {
    width: 100%;
    height: 100%;
}

.apartment-page + .iframe-container .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: var(--first-border-color);
    cursor: pointer;
}

@media (max-width: 992px) {
    .vertical-scroll-calendar {
        max-height: 320px;
    }

    .map-details-container, .recommendations-container {
        margin-top: 30px;
    }
    
    .iframe-container {
        width: 100%;
        height: 100%;
    }

    .recent-views button {
        margin-bottom: 0;
    }

    .weather-container {
        margin-bottom: 20px;
    }
}

@media (hover: hover) and (pointer: fine) {
    .clear-btn:hover {
        background-color: var(--second-background-color);
        border: 1px solid var(--second-background-color);
    }

    .reserve-btn:hover {
        background-color: var(--second-background-color);
        border: 1px solid var(--second-background-color);
    }    

    .recent-views button:hover {
        color: var(--text-color);
        background-color: var(--first-background-color);
        border: none;    
    }
  }