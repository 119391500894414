/* Custom Navbar Styles */
.custom-navbar {
    height: 100px;
    padding: 20px 0;
    z-index: 998; /* Lower than fixed MainBar */
}

.custom-navbar.fixed {
    z-index: 999;
    background: var(--first-background-color);
}

.nav-link {
    color: var(--text-color) !important;
}

/* Hamburger Toggle Styles */
.hamburger-toggle {
    padding: 15px;
    background: var(--second-background-color);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hamburger-toggle.open {
    background-color: var(--second-background-color);
}

.hamburger-toggle:active {
    background-color: var(--second-background-color) !important;
}

.hamburger-toggle::after {
    display: none;
}

.hamburger-toggle:hover {
    background: var(--second-background-color);
}

.hamburger-icon {
    width: 20px;
    height: 2px;
    background-color: var(--first-background-color);
    position: relative;
    transition: background-color 0.15s ease-in-out 0.3s;
}

.hamburger-icon::before,
.hamburger-icon::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background-color: var(--first-background-color);
    left: 0;
}

.hamburger-icon::before {
    top: -6px;
}

.hamburger-icon::after {
    top: 6px;
}

.hamburger-toggle.open .hamburger-icon {
    background-color: transparent;
    transition: background-color 0.15s ease-in-out;
}

.hamburger-toggle.open .hamburger-icon::before {
    top: 0;
    transform: rotate(45deg);
    transition: top 0.15s ease-in-out, transform 0.3s ease-in-out 0.15s;
}

.hamburger-toggle.open .hamburger-icon::after {
    top: 0;
    transform: rotate(135deg);
    transition: top 0.15s ease-in-out, transform 0.3s ease-in-out 0.15s;
}

.hamburger-toggle .hamburger-icon::before,
.hamburger-toggle .hamburger-icon::after {
    transition: transform 0.3s ease-in-out, top 0.15s ease-in-out 0.3s;
}

/* Dropdown Menu Styles */
.dropdown-menu.hamburger {
    background: var(--second-background-color);
}

.dropdown-item {
    color: var(--first-background-color);
}

.dropdown-item :first-child {
    color: var(--first-background-color);
}

.dropdown-item:hover {
    background-color: var(--second-background-color);
    color: var(--text-color);
}

.dropdown-item.active {
    background-color: transparent;
    color: var(--first-background-color);
}

.dropdown-item.active:hover {
    background-color: transparent;
    color: var(--text-color);
}

/* Bottom Navbar Styles */
.bottom-navbar {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 70px;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
    z-index: 1000;
    padding: 0;
}

.bottom-navbar .nav {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

/* Responsive Styles for Navbar Brand Image */
@media (max-width: 1200px) {
    .navbar-brand img {
        height: 45px;
    }
}

@media (max-width: 992px) {
    .navbar-brand img {
        height: 40px;
    }
}

@media (max-width: 768px) {
    .navbar-brand img {
        height: 35px;
    }
}

@media (max-width: 576px) {
    .navbar-brand img {
        height: 30px;
    }
}