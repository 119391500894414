/* Location dropdown styling */
.location-dropdown {
    background-color: transparent;
    border-radius: 8px;
    color: var(--text-color);
    border: none;
}

.dropdown-header {
    font-size: 12pt;
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--first-background-color);
}

.dropdown-header span {
    display: none;
}

/* Search input styling */
.search-input {
    width: 100%;
    border: none;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--first-background-color);
    background-color: transparent;
    color: var(--first-background-color) !important;
    transition: background-color 0s;
}

.search-input:focus {
    border: 1px solid var(--first-background-color);
    outline: none;
    box-shadow: none;
    background-color: transparent;
}

.search-input::placeholder {
    color: var(--first-background-color); /* Placeholder color */
}

/* Dropdown options container styling */
.dropdown-options {
    gap: 10px;
    justify-content: center;
    max-height: 200px;
    overflow-y: auto;
    border-bottom: 1px solid var(--first-background-color);
    border-top: 1px solid var(--first-background-color);
}

/* Individual dropdown item styling */
.dropdown-options .dropdown-item {
    padding: 10px;
    border-radius: 5px;
    margin: 5px 0;
    text-align: center;
    border: 1px solid var(--first-background-color);
    cursor: pointer;
    background-color: transparent;
    color: var(--first-background-color);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dropdown-options .dropdown-item:hover {
    background-color: var(--first-background-color);
    color: var(--text-color);
    border: 1px solid var(--first-background-color);
}

.dropdown-options .dropdown-item.selected {
    background-color: var(--first-background-color);
    color: var(--text-color);
    border-color: var(--second-border-color);
}

.dropdown-options .dropdown-item.selected:hover {
    background-color: var(--first-background-color);
}

/* Dropdown buttons container styling */
.location-dropdown .clear-btn {
    color: var(--first-background-color);
    background-color: var(--second-background-color);
    border: 1px solid var(--first-background-color);
}

.location-dropdown .clear-btn:hover {
    color: var(--text-color);
    background-color: var(--first-background-color);
}

.location-dropdown .continue-btn {
    border: 1px solid var(--second-background-color);
    background-color: var(--first-background-color);
    color: var(--text-color);
}

.location-dropdown .continue-btn:hover {
    background-color: var(--second-background-color);
    border: 1px solid var(--first-background-color);
    color: var(--first-background-color);
}

.location-dropdown .continue-btn:disabled{
    background-color: var(--second-background-color) !important;
    border: 1px solid var(--second-background-color) !important;
}

/* Styling adjustments for screen widths 992px and above */
@media (min-width: 992px) {
    .location-dropdown {
        position: absolute !important;
        top: 140% !important;
        left: 50% !important;
        transform: translate(-50%);
        width: 60%;
        border: 1px solid var(--first-background-color);
        background-color: var(--second-background-color);
        padding: 10px;
    }

    .dropdown-options {
        margin-bottom: 10px;
    }

    .search-input {
        border: 1px solid var(--first-background-color);
    }

    .dropdown-header span {
        display: block;
    }
}