.search-bar {
    position: relative;
    border: 1px solid #262626;
    border-radius: 8px;
    margin: 0 auto;
    background: linear-gradient(rgb(15, 15, 15) 0%, rgb(26, 26, 26) 100%), rgb(20, 20, 20);
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0px 5px;
}
  
.search-bar input {
    width: 100%;
    height: fit-content;
    font-size: 11pt;
    color: #fff;
    background-color: rgba(20, 20, 20, 0.8); /* Fallback color */
    border: none;
    outline: none;
    padding-left: 20px;

}
  
.search-bar button {
    background-color: #333;
    color: #fff;
    border: 1px solid #1e1e1e;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px;
    padding: 8px;
}
  
.search-bar button:hover {
    background-color: #444;
}  