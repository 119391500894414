.contactus-header {
    position: relative;
    text-align: center;
    color: var(--second-background-color);
}

.contactus-page .iframe-container {
    box-sizing: border-box;
    height: 100vh; 
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.contactus-page .iframe-container iframe {
    padding-top: 350px;
    height: calc(100% - 10px);
}

@media (max-width: 1400px) {
    .contactus-page .iframe-container iframe {
        padding-top: 250px;
    }
}

@media (max-width: 992px) {
    .contactus-page .iframe-container iframe {
        padding-top: 400px;
    }
}

@media (max-width: 576px) {
    .contactus-page .iframe-container iframe {
        padding-top: 200px;
    }
}