/* General Styles */
.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-container.hidden {
  display: none;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: brightness(60%);
}

.homepage-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 3rem;
  font-family: 'Playfair Display', serif;
  z-index: 1;
}

.arrow-btn {
  position: absolute;
  bottom: 30px;
  left: 50%;
  font-size: 1.2rem;
  font-family: 'Playfair Display', serif;
  transform: translate(-50%);
  z-index: 1;
  color: var(--first-background-color);
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: transparent;
  border: none;
}

.map-view-button {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 20px;
  background-color: var(--first-background-color);
  color: var(--text-color);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
  text-wrap: nowrap;
}

/* Hover styles for non-touch devices */
.map-view-button:hover {
  background-color: var(--second-background-color);
  color: var(--first-background-color);
}

.banner-container {
  margin-top: 50px;
}

/* Media Queries */
@media (max-width: 1200px) {
  .arrow-btn {
    font-size: 1rem;
    bottom: 20px;
  }
}

@media (max-width: 992px) {
  .video-container {
    height: calc(100vh - 70px);
  }
}

/* Touch Devices - Disable hover styles */
@media (pointer: coarse) {
  .map-view-button:hover {
    background-color: white; /* Neutral color for touch devices */
    color: black;
  }
}