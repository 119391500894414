/* No results message styling */
.no-results {
    font-size: 1.2rem; 
    color: var(--text-color); 
    padding: 20px;
    margin: 20px;
    background-color: var(--second-background-color);
    border-radius: 8px; 
    text-align: center; 
}

/* Apartments list container */
.apartments-list {
    width: 100%;
    margin: 0;
    padding-top: 20px;
    row-gap: 30px;
}

/* Apartment card styling */
.apartment-card {
    background-color: transparent;
    cursor: pointer;
    border: 1px solid #fcf7f2;
}

/* Apartment carousel container */
.apartment-carousel {
    border-radius: 6px;
    overflow: hidden;
    transition: transform 0.2s ease;
}

/* Apartment carousel images */
.apartment-carousel img {
    height: 300px;
    object-fit: cover;
}

/* Hover effect on apartment card */
.apartment-card:hover .apartment-carousel {
    transform: scale(1.01);
}

/* React multi-carousel item styling */
.react-multi-carousel-item {
    transition: transform 0.2s ease;
}

/* Custom arrows for the carousel */
.custom-left-arrow,
.custom-right-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;
    background-color: transparent;
    border-radius: 50%;
    z-index: 1;
    opacity: 0; /* Default: hidden */
}

/* Positioning the left arrow */
.custom-left-arrow {
    left: 10px;
}

/* Positioning the right arrow */
.custom-right-arrow {
    right: 10px;
}

/* Show arrows on hover */
.apartment-card:hover .custom-left-arrow,
.apartment-card:hover .custom-right-arrow {
    opacity: 1;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    /* Limit carousel height on smaller screens */
    .apartment-carousel {
        max-height: 200px;
    }

    /* Disable hover effect on carousel in smaller screens */
    .apartment-card:hover .apartment-carousel {
        transform: none;
    }

    /* Always show arrows on mobile */
    .custom-left-arrow,
    .custom-right-arrow {
        display: block;
        opacity: 1;
    }
}

/* Styling for the carousel dots */
.apartment-carousel .react-multi-carousel-dot button {
    width: 8px !important;
    height: 8px !important;
    border-radius: 50%;
    border: none;
}

/* Active dot styling */
.apartment-carousel .react-multi-carousel-dot--active button {
    background: var(--text-color);
}

/* Remove bottom margin from the carousel list */
.react-multi-carousel-list {
    margin-bottom: 0 !important;
}

/* Apartment city name styling */
.apartment-city {
    text-transform: uppercase;
    font-size: 9pt;
    font-weight: bold;
    color: var(--icon-color);
}

/* Apartment name styling */
.apartment-name {
    color: var(--text-color);
    font-weight: bold;
}

/* Apartment information container */
.apartment-info {
    display: flex;
    gap: 15px;
}

.apartment-info div {
    display: flex;
    align-items: center;
}

/* Apartment icon styling */
.apartment-icon {
    margin-right: 5px;
    color: var(--icon-color);
}

/* Styling for the span next to apartment icon */
.apartment-icon + span {
    color: var(--text-color);
}

/* Placeholder image styling for apartment cards */
.apartment-card .placeholder-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    background-color: var(--second-background-color);
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 8px;
    border: 1px solid var(--second-border-color);
}