/* Date picker container styling */
.date-picker {
    background-color: transparent;
    border-radius: 8px;
    color: var(--first-background-color);
}

/* React Day Picker (rdp) global styling */
.rdp {
    margin: 0;
    padding: 20px 0;
}

/* Month container styling within rdp */
.rdp-months {
    justify-content: center;
}

/* Button hover effect within rdp */
.rdp-button:hover {
    background-color: var(--first-background-color) !important;
    color: var(--second-background-color);
}

/* Styling for selected days */
.rdp-day_selected,
.rdp-day_selected:focus {
    background-color: var(--first-background-color) !important;
    color: var(--text-color);
}

/* Styling for the start and end of date range */
.rdp-day_range_start,
.rdp-day_range_end {
    isolation: isolate;
    position: relative;
    font-weight: 700;
}

/* Pseudo-elements for start and end of date range */
.rdp-day_range_end:after,
.rdp-day_range_start:after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    border-radius: 100%;
    background: #fff;
}

/* Hover effect for the start and end of date range */
.rdp-day_range_start:hover,
.rdp-day_range_end:hover {
    color: var(--text-color);
}

/* Styling for days within the date range */
.rdp-day_range_middle {
    background-color: var(--second-background-color);
    color: var(--text-color);
}

/* Styling for today's date */
.rdp-day_today {
    border: none;
    font-weight: bold;
    color: var(--text-color);
}

/* Styling for disabled days */
.rdp-day_disabled {
    color: var(--text-color);
    position: relative;
}

/* Strikethrough effect for disabled days */
.rdp-day_disabled::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    height: 1px;
    background: var(--text-color);
    transform: translate(-50%, -50%) rotate(-45deg);
}

.rdp-day_disabled::before:hover {
    color: var(--second-background-color);
}

/* Button container styling */
.buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.date-picker .clear-btn {
    color: var(--first-background-color);
    background-color: var(--second-background-color);
    border: 1px solid var(--first-background-color);
}

.date-picker .clear-btn:hover {
    color: var(--text-color);
    background-color: var(--first-background-color);
}

.date-picker .continue-btn {
    border: 1px solid var(--second-background-color);
    background-color: var(--first-background-color);
    color: var(--text-color);
}

.date-picker .continue-btn:disabled {
    background-color: var(--second-background-color) !important;
    border: 1px solid var(--second-background-color) !important;
}

.date-picker .continue-btn:hover {
    background-color: var(--second-background-color);
    border: 1px solid var(--first-background-color);
    color: var(--first-background-color);
}

/* Styling adjustments for screen widths 992px and above */
@media (min-width: 992px) {
    .date-picker {
        position: absolute;
        top: 140%;
        left: 50%;
        transform: translate(-50%);
        z-index: 1000;
        transition: opacity 10s ease-in-out;
        padding: 10px;
        border: 2px solid var(--first-background-color);
        background-color: var(--second-background-color);
    }

    .rdp-day_range_middle {
        background-color: var(--first-background-color);
        color: var(--second-background-color);
    }

    .rdp-day_selected,
    .rdp-day_selected:focus {
        background-color: var(--first-background-color);
    }
}