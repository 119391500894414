.guests-dropdown {
    border-radius: 8px; 
    color: var(--first-background-color);
    background-color: transparent;
}

@media (min-width: 992px) {
    .guests-dropdown {
        position: absolute !important;
        top: 140% !important;
        left: 50% !important;
        transform: translate(-50%);
        width: 60%;
        background-color: var(--second-background-color);
        border: 2px solid var(--first-background-color);
        padding: 10px;
    }
}

.guests-label {
    display: flex;
    flex-direction: column;
}

.guests-label span {
    color: var(--first-background-color);
}

.guests-handler {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 0;
}

.guests-handler input {
    background-color: transparent;
    color: var(--first-background-color);
    border: none;
}

.guests-handler button {
    background-color: transparent;
    border-color: var(--first-background-color);
    border-radius: 50%;
    font-size: larger;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
}

.guests-handler button:not(:disabled):hover {
    background-color: var(--first-background-color);
    border-color: var(--second-background-color);
    color: var(--second-background-color);
}

.guests-handler button:active {
    background-color: var(--first-background-color) !important;
    border-color: var(--second-background-color) !important;
    color: var(--second-background-color) !important;
    transform: scale(95%);
}

.guests-handler button:disabled {
    border: none;
    background-color: transparent !important;
    pointer-events: all;
    cursor: not-allowed;
}

.guests-dropdown .clear-btn {
    color: var(--first-background-color);
    background-color: var(--second-background-color);
    border: 1px solid var(--first-background-color);
}

.guests-dropdown .clear-btn:hover {
    color: var(--text-color);
    background-color: var(--first-background-color);
}

.guests-dropdown .search-btn {
    border: 1px solid var(--second-background-color);
    background-color: var(--first-background-color);
    color: var(--text-color);
}

.guests-dropdown .search-btn:disabled {
    background-color: var(--second-background-color) !important;
    border: 1px solid var(--second-background-color) !important;
}

.guests-dropdown .search-btn:hover {
    background-color: var(--second-background-color);
    border: 1px solid var(--first-background-color);
    color: var(--first-background-color);
}
