#main-bar {
  width: 100%;
  padding: 20px;
  z-index: 1000;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%);
}

#main-bar.fixed {
  position: fixed;
  padding: 30px;
  top: 0;
  z-index: 1000;
}

/* Desktop adjustments */
@media (min-width: 992px) {
  #main-bar {
    width: 50%;
  }
}
