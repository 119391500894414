.partner-banner-container {
  margin-top: 30px;
  padding: 20px 0;
  background-color: var(--first-background-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.partner-title {
  margin-bottom: 20px;
  color: var(--text-color);
  font-size: 1.5rem;
  text-align: center;
}

.partner-images-wrapper {
  border-top: 3px solid var(--second-background-color);
  border-bottom: 3px solid var(--second-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex-wrap: wrap;
  width: 100%;
}

.partner-img {
  max-height: 100%;
  object-fit: contain;
  margin: 0 20px;
  transition: transform 0.3s ease;
}

.partner-img:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .partner-images-wrapper {
    padding: 20px 0;
    flex-direction: column;
    gap: 20px;
  }
}
