:root {
  --first-background-color: #fcf7f2;
  --second-background-color: #8a715b;
  --first-border-color: #333;
  --second-border-color: #cccccc;
  --text-color: #51443f;
  --icon-color: #51443f;
}

body {
  background-color: var(--first-background-color);
  color: var(--text-color);
}