.container-photos > .row {
    height: 500px;
    overflow: hidden;
}

.container-photos > .row > * {
    height: 100%;
    border-radius: 10px;
}

.container-photos > .row > :last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    gap: 20px; 
    height: 100%;
}

.container-photos img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    border-radius: 10px;
}

.container-photos > .row > .col-3 > * {
    flex: 1; 
    height: calc(50% - 10px); 
}

.image-container {
    position: relative;
}

.see-all-photos-button {
    position: absolute;
    padding: 3px 6px;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
    color: var(--text-color);
    background-color: var(--first-background-color);
}

.see-all-photos-button:hover {
    color: var(--first-background-color);
    background-color: var(--second-background-color);
    border: 1px solid var(--first-background-color);
}

.container-photos .placeholder-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    background-color: var(--second-background-color);
    color: var(--text-color); 
    font-size: 16px; 
    font-weight: bold; 
    text-align: center;
    border-radius: 8px; 
    border: 1px solid var(--second-border-color); 
}